import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openid: '',
		consent: 0, // 用户协议
    // baseURL: 'http://bp2018.rsproject.cn/dev-test-manke' // 瑞升
    baseURL: 'https://curemap.msdcnmrl.cn' // 默沙东正式
    // baseURL: 'https://curemap-test.msdcnmrl.cn' // 默沙东测试
  },
  mutations: {
    change (state, openid) {
      state.openid = openid
    },
		changeConsent (state, consent) {
			state.consent = consent
		}
  }
}) 
